import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const Wrapper = styled.div`
  margin: 100px auto;
`

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;

  h2 {
    font-size: 24px;
    margin: 0;
  }

  p {
    font-size: 16px;
    color: #555;
  }

  a {
    color: #007acc;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

const Privacy = () => {
  return (
    <Layout>
      <Wrapper>
        <section>
          <p>Last updated: 01/1/2024</p>
          <p>
            At Light Pole Defender, accessible from
            https://lightpoledefender.com, one of our main priorities is the
            privacy of our visitors. This Privacy Policy document contains types
            of information that is collected and recorded by Light Pole Defender
            and how we use it.
          </p>
          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>
        </section>

        <section>
          <h2>Consent</h2>
          <p>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </p>
        </section>

        <section>
          <h2>Information we collect</h2>
          <p>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </p>
          <p>
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </p>
          <p>
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </p>
        </section>

        <section>
          <h2>How we use your information</h2>
          <p>
            We use the information we collect in various ways, including to:
          </p>
          <ul>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>
        </section>
      </Wrapper>
    </Layout>
  )
}

export default Privacy
